import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import SurveyForm from "./SurveyForm";
import NotFoundComponent from "./NotFoundComponent";
import Confirmation from "./Confirmation.vue";
import LegalNotice from "./LegalNotice";
import Bo from "./BOComponents/Bo";
import BoJeux from "./BOComponents/Jeux/BoJeux";
import BoListeJeux from "./BOComponents/Jeux/ListeJeux";
import BoQuizz from "./BOComponents/Quizz/BoQuizz";
import BoListeQuizz from "./BOComponents/Quizz/ListeQuizz";
import BoCreationQuizz from "./BOComponents/Quizz/CreationQuizz";
import BoModificationQuizz from "./BOComponents/Quizz/ModificationQuizz";
import BoErreurQuizz from "./BOComponents/Quizz/ErreurQuizz";
import BoLogin from "./BOComponents/Login/Login.vue";
import Reglement from "./Reglement";
import ErrorAPI from "./ErrorApi.vue";
import DonneesPerso from "./DonneesPersonnelles";

import HorsDelais from "./HorsDelais";
import JeuNotExist from "./JeuNotExist";
import DejaParticiper from "./DejaParticiper";
import { routes as ImagesRoutes } from "./BOComponents/banque-images/index";

const routes = [
  {
    name: "notFound",
    path: "/:pathMatch(.*)*",
    component: NotFoundComponent,
    meta: {
      title: "404 Not Found",
    },
  },
  {
    name: "CreerJeu",
    path: "/bo/jeux/creerjeu",
    redirect: { name: "BO.CreationJeu" },
  },
  {
    name: "ModifierJeu",
    path: "/bo/modifierjeu/:id",
    redirect: { name: "BO.Jeux" },
  },
  {
    name: "JeuForm",
    path: "/jeu_*_form/:id",
    redirect: to => {
      return {
        name: "Questionnaire",
        params: { section: "jeu_accueil", param: to.params.id },
      };
    },
  },
  {
    path: "/bo",
    component: Bo,
    redirect: { name: "BO.ListeJeux" },
    children: [
      {
        name: "BO.Login",
        path: "login",
        component: BoLogin,
      },
      {
        path: "jeux",
        component: BoJeux,
        name: "BO.Jeux",
        redirect: { name: "BO.ListeJeux" },
        children: [
          {
            name: "BO.ListeJeux",
            path: "listejeux",
            component: BoListeJeux,
          },
          {
            name: "BO.CreationJeu",
            path: "creerjeu",
            component: SurveyForm,
            meta: {
              title: "Création Jeu",
              section: "bo_nouveau_jeu",
            },
          },
          {
            name: "BO.ModificationJeu",
            path: "modifierjeu/:param",
            component: SurveyForm,
            meta: {
              title: "Modification Jeu",
              section: "bo_modifier_jeu",
            },
          },
        ],
      },
      {
        name: "BO.Quizz",
        path: "quizz",
        component: BoQuizz,
        redirect: { name: "BO.ListeQuizz" },
        children: [
          {
            name: "BO.ListeQuizz",
            path: "listequizz",
            component: BoListeQuizz,
            meta: { section: "quizz_questions" }
          },
          {
            name: "CreationQuizz",
            path: "creerquizz",
            component: BoCreationQuizz,
            meta: { section: "quizz_questions" }
          },
          {
            name: "ModificationQuizz",
            path: "modifierquizz/:id?",
            component: BoModificationQuizz,
            meta: { section: "quizz_questions" }
          },
          {
            name: "ErreurQuizz",
            path: "erreurquizz",
            component: BoErreurQuizz,
            meta: { section: "quizz_questions" }
          },
        ],
      },
      ...ImagesRoutes,
    ],
    meta: {
      title: "BO",
    },
  },
  {
    name: "Accueil",
    path: "/",
    redirect: { name: "BO.Jeux" },
  },
  {
    name: "Callback", // Pour les cas où la route de retour après login est vide
    path: "/callback",
    redirect: { name: "BO.Jeux" },
  },
  {
    name: "Complet",
    path: "/completer/:param?",
    component: Confirmation,
    meta: {
      title: "Complet",
      keepAlive: true,
      section: "confirmation",
    },
  },
  {
    name: "Règlement",
    path: "/reglement/:param?",
    component: Reglement,
    meta: {
      title: "Règlement",
      keepAlive: true,
    },
  },
  {
    name: "Hors Délai",
    path: "/horsdelai",
    component: HorsDelais,
    meta: {
      title: "Hors Délai",
      keepAlive: true,
      section: "horsdelai",
    },
  },
  {
    name: "Jeu non présent",
    path: "/jeuNonPresent",
    component: JeuNotExist,
    meta: {
      title: "Jeu non présent",
      keepAlive: true,
      section: "jeuNonPresent",
    },
  },
  {
    name: "Deja participer",
    path: "/dejaParticiper",
    component: DejaParticiper,
    meta: {
      title: "Deja participer",
      keepAlive: true,
      section: "dejaParticiper",
    },
  },
  {
    name: "Mentions légales",
    path: "/mentions_legales/:param?",
    component: LegalNotice,
    meta: {
      title: "Mentions légales",
      keepAlive: true,
    },
  },
  {
    name: "Données personnelles",
    path: "/donnees_perso/:param?",
    component: DonneesPerso,
    meta: {
      title: "Données personnelles",
      keepAlive: true,
    },
  },
  {
    name: "ErrorAPI",
    path: "/error/:id",
    component: ErrorAPI,
    props: true,
    meta: {
      title: "Server Error",
    },
  },
  {
    name: "Questionnaire",
    props: route => ({ query: route.query.q }),
    path: "/:section/:param?",
    component: SurveyForm,
    meta: route => ({ 
      title: "Questionnaire",
      section: route.params.section // Màj vue 3 : on va passer la section en meta également (alors qu'elle est déjà en param)
      // Depuis Vue Router 4, il n'est plus possible de passer des params qui ne figurent pas dans l'URL (c'était déjà une mauvaise pratique avant) : https://github.com/vuejs/router/blob/main/packages/router/CHANGELOG.md#414-2022-08-22
      // Hors c'était utilisé un peu partout dans la webapp. Pour ne pas avoir à tout refaire, on passe donc la section dans une meta.
    }),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from) => {
  console.log("Routing to", to);

  // Pour le component Questionnaire, la section est passée en param.
  // Pour tous les autres c'est en meta (changement vue router 4)
  // Cf. explication 10 lignes au-dessus.
  if (to.params?.section !== undefined) {
    to.meta.section = to.params.section;
  }

  let userEmail = to.query.useremail;
  if (userEmail) {
    await store.dispatch("setUtilisateur", { email: userEmail }).then(() => { return { name: "BO.Jeux" }; });
  }

  if (to.path.indexOf("bo") >= 0) {
    if (to.path !== "/bo/login") {
      let isLogged = store.state.survey.sections.globals.isUserLogged;
      if (!isLogged) {
        window.location.href = `${process.env.VUE_APP_API_URL}/api/login`;
        return { name: "BO.Login" };
      }
    }
  }
  
  return true;
});

router.afterEach(to => {
  if (to.meta.title !== undefined) {
    document.title = `${to.meta.title} - Jeu Concours`;
  }
});

export default router;
